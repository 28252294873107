<template>
  <div>
    <div ref="chat"><flixLoader /></div>
  </div>
</template>
<script>
import { externalChat } from '@/components/chat/chatUpdate.js'
export default {
  components: {},
  props: {},
  data () {
    var d = new Date()
    return {
      sendData: {
        email: false,
        msg: false
      },
      bookingLink: false,
      calendar: false,
      user: false,
      chatData: {},
      answerCount: 0,
      messages: [
        {
          userID: 1,
          view: ['1', '4'],
          msg: 'Hallo! Gibt es Fragen oder Unklarheiten im Zusammenhang mit dem Termin?',
          date: d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate() + ' ' + d.getHours() + ':' + d.getMinutes()
        }
      ]
    }
  },
  methods: {
    addSaveMessage () {
      var d = new Date()
      this.answerCount++
      var link = this.$flix_url + this.bookingLink.href
      link = '<a href="' + link + '">' + link + '</a>'

      this.$flix_post({
        data: {
          user: this.$route.params.user,
          to: this.user.email,
          title: this.calendar.title + ' (#' + this.$route.params.id + ')',
          text: 'Es gibt eine neue Anfrage / Unklarheit von <b><a href="mailto:' + this.sendData.email + '">' + this.sendData.email + '</a></b>\n\nNachricht:\n<i>' + this.sendData.msg + '</i>\n\n' + 'Link zur Termin-Buchungs-Seite:\n' + link,
          reply: this.sendData.email
        },
        url: 'assistent/sharingmail',
        callback: function (ret) {
          window.console.log(ret)
          this.messages.push({
            userID: 1,
            view: ['1', '4'],
            msg: 'Wunderbar. Ich habe das Anliegen weitergeleitet. Der Terminverwalter meldet sich in Kürze bei Ihnen unter ' + this.sendData.email + '.',
            date: d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate() + ' ' + d.getHours() + ':' + d.getMinutes()
          })
        }.bind(this)
      })
    },
    addEmailFailMessage () {
      var d = new Date()
      setTimeout(function () {
        this.messages.push({
          userID: 1,
          view: ['1', '4'],
          msg: 'Oh. Da ist ein Fehler passiert. Die E-Mailadresse scheint nicht zu funktionieren. Bitte erneut eintippen.',
          date: d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate() + ' ' + d.getHours() + ':' + d.getMinutes()
        })
      }.bind(this), 2000)
    },
    addFirstMessage (msg) {
      this.answerCount++
      var d = new Date()
      this.sendData.msg = this.messages[this.answerCount].msg
      setTimeout(function () {
        this.messages.push({
          userID: 1,
          view: ['1', '4'],
          msg: 'Vielen Dank für die Nachricht! Ich leite das Anliegen gerne an den Terminverwalter weiter.',
          date: d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate() + ' ' + d.getHours() + ':' + d.getMinutes()
        })
      }.bind(this), 2000)

      d = new Date()

      setTimeout(function () {
        this.messages.push({
          userID: 1,
          view: ['1', '4'],
          msg: 'Dazu brauche ich Ihre <u>E-Mailadresse</u>, damit die Antwort des Terminverwalters schnellstmöglich erfolgen kann.',
          date: d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate() + ' ' + d.getHours() + ':' + d.getMinutes()
        })
      }.bind(this), 5000)

      d = new Date()

      setTimeout(function () {
        this.messages.push({
          userID: 1,
          view: ['1', '4'],
          msg: 'Bitte Emailadresse unten bei "Nachricht verfassen" eintippen.',
          date: d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate() + ' ' + d.getHours() + ':' + d.getMinutes()
        })
      }.bind(this), 8000)
    },
    addMessage () {
      if (this.answerCount === 0) {
        this.addFirstMessage()
        return false
      }
      if (this.answerCount === 1) {
        var email = Object.values(this.messages)[Object.values(this.messages).length - 1].msg
        var re = /\S+@\S+\.\S+/
        if (re.test(email)) {
          this.sendData.email = email
          this.addSaveMessage()
        } else {
          this.addEmailFailMessage()
        }
      }
    },
    loadChat () {
      this.chatData = {
        language: this.$i18n.locale,
        messages: this.messages,
        legend: 'active', // false | all | active | me | array [1,2]
        activeUser: 4,
        users: {
          1: { name: 'Flix von Terminflix', background: '#717c80', color: '#fff', avatar: this.$flix_url + '/assets/img/chat/flix_avatar.jpeg' },
          4: { name: 'Ich', background: this.$store.getters.colors[1], color: '#fff', avatar: this.$flix_url + '/assets/img/chat/customer_avatar.jpg' }
        },
        onSubmit: function (msg) {
          this.addMessage(msg)
          return false
        }.bind(this),
        update: false
      }
      new externalChat(function () { }).run(this.chatData, this.$refs.chat)
    },
    getUser (callback) {
      this.$flix_post({
        url: 'user/get_user',
        data: { user: this.$route.params.user },
        callback: function (ret) { if (ret.data[0]) { this.user = ret.data[1]; callback() } }.bind(this)
      })
    },
    getCalendar (callback) {
      this.$flix_post({
        url: 'assistent/get_data',
        data: { user: this.$route.params.user, ID: this.$route.params.id },
        callback: function (ret) { if (ret.data[0]) { this.calendar = ret.data[1]; callback() } }.bind(this)
      })
    }
  },
  mounted () {
    this.getUser(
      function () {
        this.getCalendar(
          function () {
            this.bookingLink = this.$router.resolve({ name: 'bookingPage', params: { username: this.user.username, lang: this.user.language, id: this.$route.params.id } })
            this.loadChat()
          }.bind(this)
        )
      }.bind(this))
  },
  computed: {

  }
}
</script>
<style lang='sass'>
  #jj_chat_area .msg_area, #jj_chat_area .translation_area, #jj_chat_area .insert_area, #jj_chat_area .writing_area, #jj_chat_area .pause_area
    width: 100% !important
</style>
